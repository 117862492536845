::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

html {
    -webkit-text-size-adjust: 100%;
}

body {
    overflow-y: hidden;
}

.al-page-container {
    flex: 5;
    background: #f4f6fb;
    height: 100%;
    padding: 0 !important;
}

.admin-table-wrapper {
    display: block;
    position: relative;
    height: 600px;
    padding: 0 2rem 7rem 0.7rem;
}

.admin-h3 {
    color: #596980;
    padding-bottom: 1rem;
}

.white-bg {
    background: none no-repeat scroll 0 0 #fff;
    position: relative;
    padding: 0.5rem 2rem 2rem 2rem;
    border-radius: 1.5rem;
}

.form-wrapper {
    padding: 1rem 0;
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
}

.div {
    display: flex;
    flex: 2;
}

.section-calendar,
.section-select-user,
.section-select-event-type,
.section-btn-go {
    padding: 0;
    margin: 0;
    padding-right: 0.6rem;
    position: relative;
}

.admin-option {
    color: #6c757d;
    font-size: 1rem;
    line-height: 125%;
    vertical-align: top;
}

#date-time {
    width: 7rem;
    padding: 6px 20px;
    font-weight: 400 !important;
    font-family: SF Pro Display;
    font-size: 14px;
}

#user {
    width: 0rem;
    padding: 6px;
    font-weight: 400 !important;
    font-family: SF Pro Display;
    font-size: 14px;
}

#activity {
    width: 0rem;
    padding: 6px;
    font-weight: 400 !important;
    font-family: SF Pro Display;
    font-size: 14px;
}

#entity-affected {
    width: 10rem;
    padding: 6px;
    font-weight: 400 !important;
    font-family: SF Pro Display;
    font-size: 14px;
}

#entity-affected {
    text-align: center;
    vertical-align: middle;
}

#select-user,
#select-event-type {
    background: url('https://api.iconify.design/akar-icons/chevron-down.svg?color=%236c757d&width=15') no-repeat scroll 8.4rem 0.55rem;
    color: #6c757d;
    font-size: 0.9rem;
    height: 35px;
}

#select-event-type {
    width: 10rem;
    padding: 0 30px 0 10px;
    -webkit-padding-end: 30px;
    -webkit-padding-start: 10px;
}

#select-user {
    width: 10rem;
    padding: 0 30px 0 10px;
    -webkit-padding-end: 30px;
    -webkit-padding-start: 10px;
}

#select-event-type {
    width: 10rem;
}

#date-range-picker {
    color: #6c757d;
    padding: 0 0 0 12px;
    font-size: 0.9rem;
    height: 35px;
    width: 14rem;
}

box-icon {
    position: absolute;
    /*left: 215px;*/
    top: 0px;
    background-color: #e3e7ee;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 40px;
    padding: 0;
    border: 1px solid #e3e7ee;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-width: thin;
    right: 0.6rem;
}

#search {
    background: url('https://api.iconify.design/mdi/magnify.svg?color=%239ca5b4&width=22') no-repeat scroll 15rem 6px;
    background-color: #F4F6FB !important;
    color: #6c757d;
    font-style: italic;
    font-size: 12px;
    border-radius: 25px;
    width: 17rem;
    height: 2.1875rem;
    border: none;
    padding: 0 42px 0 17px;
}

#search:focus {
    outline: none;
}

#admin-btn-go:focus,
#date-range-picker:focus,
#select-user:focus,
#select-event-type:focus {
    box-shadow: none !important;
}

#admin-btn-go {
    line-height: 1.2;
    width: 4.5rem;
    height: 35px;
}

.admin-thead {
    background: #F4F6FB;
    color: #8591a6;
    border-bottom: 2px solid #F4F6FB !important;
    border-top: 1px solid #F4F6FB !important;
    border-radius: 5rem !important;
}

.admin-td {
    height: 3rem;
}

.text {
    color: #6c757d;
    font-size: 14px;
    vertical-align: middle;
}

.pagination-wrapper {
    text-align: center;
    padding: 2rem 0 0 0;
}

.al-column-affected-p {
    margin: 0;
    font-weight: 400 !important;
    font-family: SF Pro Display;
}

.organizations-thead-scroll-y {
    overflow-y: auto;
    height: calc(100vh - 418px);
}

.organizations-thead-scroll-y.org-no-activity {
    height: calc(100vh - 250px);
}

.organizations-thead-scroll-y.upload-object-table {
    height: calc(100vh - 350px);
}
.upload-object-table td.org-td:nth-child(6) {
    width: 340px !important;
    word-break: break-all;
}
.admin-tbody td,
.admin-thead td {
    min-width: 180px;
}

.admin-tbody td:last-child,
.admin-thead td:last-child {
    min-width: 300px !important;
}

.admin-tbody td:first-child,
.admin-thead td:first-child {
    min-width: 100px !important;
}