#service_setting_page .EditPopup,
#kreditz_provider_page .EditPopup,
#organization_providers_page .EditPopup {
  display: flex;
  padding: 20px 0;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

#service_setting_page .EditPopup_inner,
#kreditz_provider_page .EditPopup_inner,
#organization_providers_page .EditPopup_inner {
  height: auto;
  width: 400px;
  position: static;
  padding: 15px 0px 20px;
}

#service_setting_page .EditPopup_inner {
  width: 500px;
}

#kreditz_provider_page .EditPopup_inner,
#organization_providers_page .EditPopup_inner {
  width: 350px;
}

#service_setting_page .EditPopup_inner h3,
#kreditz_provider_page .EditPopup_inner h3,
#organization_providers_page .EditPopup_inner h3 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 22px;
  padding: 0 20px 15px;
  border-bottom: 1px solid #eaeaea;
}

#service_setting_page .EditPopup_inner .row,
#kreditz_provider_page .EditPopup_inner .row,
#organization_providers_page .EditPopup_inner .row {
  padding: 0 20px;
}

#service_setting_page .EditPopup_inner label,
#kreditz_provider_page .EditPopup_inner label,
#organization_providers_page .EditPopup_inner label {
  display: flex;
  color: #5b6a83;
  font-size: 12px;
  margin: 0 0 2px;
}

#service_setting_page .EditPopup_inner select,
.add-credentials input,
#kreditz_provider_page .EditPopup_inner select,
.add-credentials input,
#organization_providers_page .EditPopup_inner select,
.add-credentials input,
.kr-provider-select .css-v68sna-control {
  width: 100%;
  height: 32px;
  outline: none;
  padding: 0 5px;
  color: #3f495b;
  font-size: 12px;
  margin: 0 0 10px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background-color: white;
  border: 1px solid lightgray;
}

.kr-provider-select .css-v68sna-control,
.kr-provider-select .css-blhudp-control {
  padding: 0 0 0 5px;
  margin: 0 0 10px;
}

.kr-provider-select .css-blhudp-control:focus,
.kr-provider-select .css-blhudp-control:hover {
  border-color: lightgray;
  box-shadow: none;
  outline: none;
}

.kr-provider-select .css-1jqq78o-placeholder {
  color: #3f495b;
  font-size: 12px;
  padding: 0 0 0 10px;
}

.kr-provider-select .css-1fdsijx-ValueContainer {
  padding: 0;
  margin: 0 0 0;
}

.kr-provider-select .css-1hb7zxy-IndicatorsContainer {
  height: 30px;
}

.kr-provider-select svg {
  fill: #3f495b;
  width: 18px;
}

.kr-provider-select .css-1u9des2-indicatorSeparator {
  display: none;
}

.kr-provider-select .css-v68sna-control:focus,
.kr-provider-select .css-v68sna-control:active,
.kr-provider-select .css-v68sna-control:focus:active,
.kr-provider-select .css-blhudp-control {
  font-size: 12px;
}

.kr-provider-select .react-select-2-listbox,
.kr-provider-select .css-1dimb5e-singleValue,
.kr-provider-select .react-select-2-listbox li,
.kr-provider-select .react-select-2-listbox a,
.kr-provider-select .react-select-2-listbox p,
.kr-provider-select .react-select-2-listbox label,
.kr-provider-select .react-select-2-listbox span {
  margin: 0 0 0 5px;
  font-size: 12px;
}

.kr-provider-select .css-qbdosj-Input,
.kr-provider-select .css-qbdosj-Input input {
  margin: 0 0 0 5px;
  font-size: 12px;
}

#service_setting_page .EditPopup_inner .modal-btns,
#kreditz_provider_page .EditPopup_inner .modal-btns,
#organization_providers_page .EditPopup_inner .modal-btns {
  display: flex;
  margin: 10px 0 0;
  padding: 20px 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #eaeaea;
}

#service_setting_page .EditPopup_inner .modal-btns button,
#kreditz_provider_page .EditPopup_inner .modal-btns button,
#organization_providers_page .EditPopup_inner .modal-btns button {
  margin: 0 10px;
}

.add-credentials {
  display: flex;
}

.add-credentials select,
.add-credentials input {
  padding: 0 10px !important;
  margin: 0 10px 10px 0 !important;
}

.add-credentials button {
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  display: flex;
  background: none;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
}

.setting-filter-container label button {
  margin: 0;
  padding: 0;
  width: 26px;
  height: 26px;
  border: none;
  display: flex;
  background: none;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
}

.add-credentials button img,
.setting-filter-container label button img {
  margin: 0;
  width: 26px;
}

.setting-filter-container label {
  align-items: center;
  margin: 0 0 10px !important;
  justify-content: space-between;
}

.setting-filter-container input {
  height: 32px;
  outline: none;
  padding: 0 5px;
  color: #3f495b;
  font-size: 12px;
  margin: 0 0 10px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background-color: white;
  border: 1px solid lightgray;
  width: 100%;
}