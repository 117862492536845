.page-container {
  flex: 5;
  background: #f4f6fb;
  height: calc(100vh - 70px);
  padding: 0 !important;
  margin-right: 30px;
}

.basic-head01-cert {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #596980;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certfy-base-head01 {
  margin: 0 0 10px;
  font-size: 24px;
}

.certify-log-detils {
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
  margin: 0 0 20px 0;

  table,
  thead,
  tr,
  th {
    font-weight: normal;
  }
}

.certify-log-detils .event-listing-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  margin: 0;
}

.certify-log-detils-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.certify-log-detils-flex-search {
  //display: flex;
  align-items: center;
  justify-content: space-between;
}

.certify-log-inner-detils {
  display: flex;
}

.organization_user_id {
  color: #596980;
  font-size: 22px;
  font-family: "SF Pro Display";
  margin: 0;
}

.certificatelogs_label {
  color: #596980;
  font-size: 12px;
  font-weight: bold;
  font-family: "SF Pro Display";
}

.certificatelogs_data {
  color: #8591a6;
  font-size: 14px;
  font-family: "SF Pro Display";
  display: inline-block;
}

.table-certlogs {
  background: #f4f6fb;
  color: #8591a6;
  border-bottom: 2px solid #f4f6fb !important;
  border-top: 1px solid #f4f6fb !important;
  border-radius: 5rem !important;
  font-size: 14px;
  font-family: "SF Pro Display";

  th {
    width: 25%;
  }
}

.table-certlogs.error-logs {
  th {
    width: 12%;
  }
}

.email_certlogs {
  color: #596980;
  font-size: 14px;
  font-family: "SF Pro Display";
}

.td_certlogs {
  color: #596980;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "SF Pro Display";
  word-wrap: break-word;
  max-width: 20px;
}

.disable-capital {
  text-transform: none;
}

.td_uppercase_certlogs {
  color: #596980;
  font-size: 14px;
  font-family: "SF Pro Display";
  text-transform: uppercase;
}

.td_token_certlogs {
  color: #596980;
  font-size: 14px;
  font-family: "SF Pro Display";
  overflow: hidden;
  word-wrap: break-word;
  max-width: 50px;
}

.td_toggle_certlogs {
  color: #596980;
  font-size: 14px;
  font-family: "SF Pro Display";
  overflow: hidden;
  word-wrap: break-word;
  max-width: 20px;
}

.td_toggle_credentials {
  color: #596980;
  font-size: 14px;
  font-family: "SF Pro Display";
  overflow: hidden;
  word-wrap: break-word;
  max-width: 100px;
}

.lowercase {
  text-transform: lowercase;
}

.log-label-details-flex .log-label-details {
  display: flex;
  width: 100%;
  align-items: center;
}

.log-label-details-flex .log-label-details ul {
  margin: 0;
  padding: 0 5px 0 0;
  flex-wrap: wrap;
  list-style-type: none;
}

.log-label-details-flex .log-label-details ul:first-child {
  margin: -5px 0 0 0;
  width: 250px;
}

.log-label-details-flex .log-label-details ul:first-child li {
  line-height: 20px;
}

.log-label-details-flex .log-label-details ul:first-child li:first-child {
  margin: 0 0 6px 0;
}

.log-label-details-flex .log-label-details ul:nth-child(2) {
  display: flex;
  width: calc(100% - 250px);
}

.log-label-details-flex .log-label-details ul li {
  padding: 0 10px 0 0;
  box-sizing: border-box;
}

.log-label-details-flex .log-label-details ul:nth-child(2) li {
  width: 27%;
  margin: 0 0 7px;
}

.log-label-details-flex .log-label-details ul li p {
  margin: 0 !important;
  line-height: 22px;
}

.log-label-details-flex .search-input .search-icon {
  right: 0;
}

.search-icon {
  cursor: pointer;
}

.list-mr-0 {
  margin: 0 !important;
}

.cl-whitespace {
  color: #fff;
  font-size: 0.3rem;
}

.hide-show {
  color: #0a58ca;
}

.request-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.refetch-transaction-btn {
  background: #3e85f9;
  color: #fff;
  border: 1px solid #3e85f9;
  border-radius: 6px;
  font-size: 12px;
  font-family: "SF Pro Display" !important;
  height: 38px;
  margin-bottom: 0.6rem;
  padding: 0 10px;
  cursor: pointer;
}

.table-head-form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dp-block {
  display: block;
}

.td-first-child {
  position: relative;
  left: 2%;
}

.td-second-child {
  position: relative;
  left: 4%;
}


select.org-id-Dropdown {
  font-size: 13px;
  width: 300px;
  margin: 0 0 15px auto;
  color: #5B6A83;
}

.requestOverviewTop {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  select {
    margin: 0 15px 0 0;
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="%235b6a83" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6l1.41-1.42Z"%2F%3E%3C%2Fsvg%3E') no-repeat scroll 17rem 0.45rem;
    height: 38px;

    &.form-control {
      &.org-id-Dropdown {
        &:focus {
          color: #5B6A83;
        }
      }
    }
  }

  .react-select-custom {
    width: 300px;
    margin-right: 15px;
  }

  #react-select-2-placeholder {
    color: #5B6A83;
    font-size: 13px;
  }
}

.certificate-modal {
  &.EditPopup_inner {
    height: 725px;
    max-width: 500px;
    border-radius: 10px;
    padding: 55px;
    width: 100%;
  }

  h5 {
    font-size: 24px;
    font-weight: 700;
    color: #5C697E;
    margin-bottom: 20px;
    text-align: center;
  }

  .secondText-update {
    font-size: 24px;
    font-weight: 500;
    color: #5C697E;
    margin-bottom: 40px;
  }

  .certificatesFound {
    height: 400px;
    overflow-y: auto;
    width: 25rem;
    margin: 0 auto;

    .firstText-update {
      font-size: 12px;
      font-weight: 500;
      color: #5b6a83;
      margin-bottom: 34px;
      text-align: left;
      display: flex;
      align-items: flex-start;
      word-break: break-all;

      input[type=checkbox] {
        width: 20px;
        height: 24px;
        margin: 3px 13px 0 0;
        border: 1px solid #000000;
      }
    }
  }
}


.kr-border-none {
  border: none;
}

.org-id-Dropdown-select {
  width: 300px;
  margin-right: 15px;
  height: 38px;

  .css-1dimb5e-singleValue {
    font-size: 13px;
  }

  div[data-value] {
    color: #5B6A83;
    font-size: 13px;
  }

  .css-1dimb5e-singleValue {
    font-size: 13px;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  svg {
    path {
      fill: #5B6A83;
    }
  }

  span+span+div {
    box-shadow: none;
    border-color: #ced4da;

    &:hover {
      border-color: #86b7fe;
    }
  }
}

.btn-submit-correct {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}


.log-label-details-flex .log-label-details ul.targetData {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}

.log-label-details-flex .log-label-details ul.targetData li {
  width: 49%;
  padding: 0;
}

.log-label-details-flex .log-label-details ul.targetData li:last-child {
  padding-left: 10px;
  padding-right: 0;
}



@media screen and (max-width: 767px) {
  .requestOverviewTop {
    flex-wrap: wrap;
    justify-content: center;

    select {
      margin: 0 0 15px 0;
    }
  }

}