/* Filter styling on Comparison report page */

.comparison-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.comparsion-header-one {
  display: flex;
  justify-content: space-between;
}

.header-left-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.search-input-comparison {
  color: #6c757d;
  font-size: 12px;
  width: 17rem;
  height: 37px;
  padding: 0 42px 0 17px;
  background-color: #ffffff;
  border: 1px solid #d6dae0;
  border-radius: 6px;
  box-sizing: border-box;
}

.search-btn-comparison {
  border: none;
  background-color: #ffffff;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.search-wrapper {
  position: relative;
}

.select-filter-wrapper {
  display: flex;
  flex-direction: column;
}

.comparison-filter-section {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #8591a6;
  overflow: hidden;
}

.select-filter-comparison {
  position: relative;
  height: 35px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid lightgray;
  background-color: white;
  color: #3f495b;
  outline: none;
  cursor: pointer;
  padding-left: 8px;
  /*padding-right: 8px; */
  margin-right: 10px;
}

.apply-filter-button-comparison {
  height: 35px;
  margin-top: 1rem;
  background-color: #3e85f9;
  border: none;
  border-radius: 3px;
  color: white;
}

.calenderI {
  background-color: #3e85f9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 8px;
  position: absolute;
  box-sizing: border-box;
  top: 68%;
  transform: translateY(-50%);
  right: 5%;
}

/*--End of Filter styling on Comparison report page */

/*-- Table content styling --*/
/* .table-header-comparison-report {
  color: #596980;
  border-bottom: 2px solid transparent;
  font-size: 14px !important;
  width: 100%;
} */

.header-rows {
  display: flex;
  background-color: #f4f6fb;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 48px !important;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  /* padding-left: 0.5rem;
  padding-right: 0.3rem; */
  font-size: 14px !important;
  color: #596980;
}

.header-rows>* {
  flex: 1 1 49px;
}

.table-header-title {
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: "SF Pro Display";
  font-weight: normal;
}

.errors-th {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: end;
  padding-top: 1rem;
}

.comparison-table-row {
  height: 48px !important;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-family: "SF Pro Display";
  color: #596980;
  padding-left: 1rem;
  padding-right: 1rem;
}

.comparison-table-row>* {
  flex: 1 1 46px;
}

.header-col-6 {
  text-align: end;
  display: flex;
  justify-content: end;
  padding-right: 8px;
  padding-top: 10px;
}

.precent {
  color: #00c48c;
}

.header-org-id {
  padding-left: 0.2rem;
}

.comparison-td-column-1 {
  margin-left: 0.2rem;
}

.comparison-td-column-1,
.table-column-one {
  box-sizing: content-box;
  flex-grow: 2;
}

.table-column-three {
  flex-grow: 1.2;
}

.td-col-6 {
  padding-right: 1rem !important;
  float: right;
}

.table-column-two,
.table-column-three,
.table-column-four,
.td-col-6 {
  display: flex;
  justify-content: flex-end;
}

.table-column-five {
  display: flex;
  justify-content: center;
}

.active-status-true {
  color: #00956a;
  background-color: #e1fae3;
}

.active-status-false {
  color: #ff647c;
  background-color: #ffe6e6;
}

.active-status-true,
.active-status-false {
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0%;
}

/*-- End of Table content styling --*/

/*--Switch-Count-styling--*/
.switch-accept-request-count {
  margin-top: 16px;
  margin-left: 0px;
  width: 194px;
  display: flex;
  align-items: center;
  border: 1px solid #e3e7ee;
  box-sizing: border-box;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-right: 1.5rem;
}

.switch-accept-request-count li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #8591a6;
  box-sizing: border-box;
  height: 35px;
  width: 97px;
  line-height: 37px;
  transition: 0.4s;
  cursor: pointer;
}

.switch-map-accept-request-count li:hover {
  background: #3e85f9 !important;
  color: #fff !important;
}

.activeAcceptedCount {
  background-color: #3e85f9 !important;
  color: #ffffff !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.activeRequestedCount {
  background-color: #3e85f9;
  color: #ffffff !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.activeAcceptedCount,
.switch-map-accept-request-count li:hover {
  background: #3e85f9 !important;
  color: #fff !important;
}

/*--End of Switch-Count-styling--*/

.paging-comparison {
  cursor: pointer;
}

.capitalize-selection {
  text-transform: capitalize;
}

.table-frame {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
}

.accepted-precent {
  color: #ff647c !important;
}