.EditPopup {
  display: flex;
  padding: 20px 0;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

.EditPopup_inner {
  width: 500px;
  height: auto;
  position: static;
  padding: 15px 0px 20px;
}

.EditPopup_inner h3 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 22px;
  padding: 0 20px 15px;
  border-bottom: 1px solid #eaeaea;
}

.directory-parent {
  display: flex;
  align-items: center;
}

.directory-upload {
  border-bottom: none;
  margin-right: 2px;
  font-size: 15px;
}

.upload-tooltip {
  height: 20px;
  cursor: pointer;
}

.EditPopupUploads {
  width: 400px;
}

.EditPopup_inner .row {
  padding: 0 20px;
}

.input-credentials input {
  width: 100%;
  height: 32px;
  outline: none;
  padding: 0 10px;
  color: #3f495b;
  font-size: 12px;
  margin: 0 0 10px;
  font-weight: 400;
  position: relative;
  border-radius: 3px;
  background-color: white;
  border: 1px solid lightgray;
}

.provider-input-file {
  position: relative;
}

.input-credentials input[type="file"] {
  border: none;
  cursor: pointer;
  padding: 4px 0 0 10px;
}

.input-credentials input[type="file"]:before {
  left: 0;
  top: 0;
  z-index: 1;
  width: 82px;
  cursor: pointer;
  font-size: 12px;
  color: #ffffff;
  line-height: 32px;
  position: absolute;
  border-radius: 7px;
  text-align: center;
  content: 'Choose File';
  background: #3E85F9;
}

.modal-btns {
  display: flex;
  margin: 10px 0 0;
  padding: 20px 0 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #eaeaea;
}

.modal-btns button {
  margin: 0 10px;
}

.deactivate-header {
  margin-bottom: 50px;
  text-align: center;
}

.deactivate-header h3 {
  border: none;
  padding: 20px 0 0;
  font-size: 26px;
}

.deactivate-header p {
  font-size: 20px;
  color: #3c3c3c;
}

.deactivate-user-data table {
  width: 100%;
}

.deactivate-user-data table td {
  border: 1px solid #dedede;
  padding: 10px;
}

.deactivate-user-data table .user-color {
  color: #898989;
}

.deactivate-user-data {
  margin: 0 30px;
}

.deactivate-user-data .form-group {
  display: flex;
}

.deactivate-user-data .form-group label {
  color: #3f495b;
  font-size: 18px;
  font-weight: 700;
}

.deactivate-user-data .form-group input,
.deactivate-user-data .form-group select {
  height: 50px;
  border: 1px solid lightgray;
  background: white;
  border-radius: 4px;
  color: #000;
}

.deactivate-black {
  color: #000;
}

.deactivate-user-data .form-group select {
  width: 100%;
}

.deactivate-user-data .form-group select:focus {
  outline: none;
}

.EditPopup_inner.organization-edit-user {
  width: 400px;
}

.organization-edit-user .deactivate-header h3 {
  color: #000;
}

.organization-edit-user .deactivate-user-data .form-group {
  flex-direction: column;
  margin: 0 0 15px;
}

.organization-edit-user .deactivate-user-data .form-group label {
  font-size: 14px;
  margin: 0 !important;
  font-weight: 500;
}

.organization-edit-user .deactivate-user-data .form-group input,
.organization-edit-user .deactivate-user-data .form-group select {
  padding: 0 15px;
}

.organization-edit-user .deactivate-user-data .form-group select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.organization-edit-user .deactivate-user-data .form-group {
  position: relative;
}

.organization-edit-user .deactivate-user-data .form-group svg {
  position: absolute;
  right: 12px;
  background: #fff;
  top: 22px;
  height: 48px;
  width: 18px;
  opacity: 0.5;
  pointer-events: none;
}

.org-create-user-pop .react-select-custom, 
.org-create-user-pop .react-select-custom .css-13cymwt-control {
  height: 50px;
}
.org-create-user-pop .react-select-custom .css-1fdsijx-ValueContainer {
  padding: 0 10px;
}
.org-create-user-pop .react-select-custom div {
  color: #000;
}
.org-create-user-pop .react-select-custom div[data-value] {
  height: 50px;
  margin: 0;
  padding: 0;
}
.org-create-user-pop .organization-edit-user .deactivate-user-data .form-group .react-select-custom svg {
  top: 0;
}

.org-create-user-pop .organization-edit-user .deactivate-header h3 {
  color: #596980;
}

.upload-object-popup .input-credentials input[type="file"] {
  height: 40px;
  font-size: 16px;
}
.upload-object-popup .input-credentials input[type="file"]:before {
  height: 100%;
  font-size: 14px;
  width: 102px;
  padding: 4px;
}
