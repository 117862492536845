* {
  margin: 0;
  padding: 0;
  transition: all 0.3s;
}
@font-face {
  src: url("../../assets/fonts/Noto_Sans/NotoSans-Regular.ttf");
  font-family: 'Noto Sans', sans-serif;
}

@font-face {
  src: url("../../assets/fonts/Noto_Sans/NotoSans-ExtraBold.ttf");
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  -webkit-text-size-adjust: 100%;
}

input:focus {
  outline: none;
}

body {
  overflow-y: hidden;
  color: #000;
}

.page-container {
  margin: 0;
  flex: 5 1;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 30px 0 0 !important;
  width: calc(100% - 290px);
}

.page-top {
  display: flex;
  justify-content: space-between;
}

.page-h3 {
  color: #596980;
  padding: 0;
  margin-left: 10px;
}

.table-wrapper {
  display: block;
  position: relative;
  overflow: auto;
  padding: 0 2rem 1.5rem 0.7rem;
}