.cert-info-container {
  flex: 5;
  height: 100%;
  background-color: #f4f6fb;
  overflow-y: scroll;

  .lower-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #596980;
  }

  .cert-info-filters-container {
    display: flex;
    flex-direction: row;
  }

  .cert-info-label {
    color: #5b6a83;
    font-size: 13px;
  }

  .flex-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .cert-info-icon {
    background-color: #3e85f9;
    margin-left: -0px;
    margin-right: 10px;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 30px;
    padding: 5px 0px 2.5px 7.5px;
    height: 39px;
  }

  #date-range-picker-cert-info {
    color: #6c757d;
    padding: 0 0 0 12px;
    font-size: 0.9rem;
    height: 39px !important;
    width: 20rem;
  }

  .cert-info-btn {
    background-color: #3E85F9;
    color: white;
    border: 2px solid #3E85F9;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    width: 15%;
    height: 39px;
    align-self: flex-end;
  }

  .cert-info-btn:hover {
    background-color: white;
    color: #3E85F9;
  }
}

.cert-info-thead {
  color: #596980;
  background: #f4f6fb;
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  font-size: 14px !important;
}

.table-head-cert-info {
  padding: 12px;
  font-weight: normal;
}

.cert-info-td {
  font-size: 14px !important;
  color: #596980;
  border-bottom: 1px solid #f4f6fb !important;
  cursor: pointer;
  padding: 10px 12px;
  font-weight: normal;
}

.cert-info-date-td,
.table-head-cert-info-date {
  width: 16%;
}

.cert-info-event-td,
.table-head-cert-info-event {
  width: 25%;
}

.ld-form-input-field {
  border-radius: 6px;
  border: 1px solid #d3d3d3;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #5b6a83;
  padding: 0.375rem 0.75rem;
  margin-right: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 18rem;
  background-color: white;
  height: 39px;

  &:focus {
    outline: none !important;
    border: 0.1px solid #62a4eb;
  }
}

.cert-info-copy-icon {
  color: #3e85f9;
  cursor: pointer;
  margin-right: 1%;
}

.org-id-Dropdown-select {
  width: 16rem;
  height: 39px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #5b6a83;
  margin-right: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.cert-switcher {
  padding-left: 10px;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 44px;
  height: 24px;
  background: #E3E7EE;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.4s, box-shadow 0.4s, border-radius 0.4s;
  top: 0;
  right: 0;
  border: 1px solid #FCFDFF;
  box-sizing: border-box;
  box-shadow: inset 0.714286px 1.42857px 4.28571px rgba(0, 33, 151, 0.25);
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 23px;
  transition: left 0.6s, transform 0.8s, width 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  cursor: pointer;
}

.react-switch-checkbox:checked+.react-switch-label {
  background: #3E85F9;
  box-shadow: 0 0 1px #2196F3;
  border: 1px solid #FCFDFF;
  box-sizing: border-box;
  box-shadow: inset 0.714286px 1.42857px 4.28571px rgba(0, 33, 151, 0.25);
  border-radius: 12.2857px;
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}