::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-y: hidden;
}

.page-container {
  flex: 5;
  background: #f4f6fb;
  height: calc(100vh - 110px);
  padding: 0 !important;
}

.table-wrapper {
  display: block;
  position: relative;
  overflow: auto;
  padding: 0 2rem 1.5rem 0.7rem;
}

.email-tr {
  display: flex;
  justify-content: flex-start;
}

#email {
  word-break: break-all;
}

.top {
  display: flex;
  justify-content: space-between;
}

.email-h3 {
  color: #596980;
  padding: 0;
  margin-left: 10px;
}

.email-header {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 15px 30px 0px 10px;
  font-weight: 400;
  font-size: 12px;
  color: #5b6a83;
}

.overflow-inherit {
  overflow: inherit !important;
}

.email-filter-container {
  position: relative;
  display: flex;
  width: 450px;
  flex-direction: column;
  margin-right: 20px;
  overflow: hidden;
}

.select-filter-email {
  position: relative;
  height: 40px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid lightgray;
  background-color: white;
  color: #5b6a83;
  margin-top: 2px;
}

.email-date-box {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  color: #596980;
  font-size: 12px;
  height: 40px !important;
  padding-left: 10px;
  margin-top: 20px;
  margin-right: -5px;
  outline: none;
  width: 450px !important;
}

.calendar-icon-email {
  background-color: #3e85f9;
  margin-right: 20px;
  margin-top: 20px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px !important;
  padding: 9px;
  width: 40px !important;
  font-size: 14px !important;
}

.email-filter-button {
  height: 40px;
  width: 250px;
  background-color: #3e85f9;
  border: 1px #3e85f9;
  border-radius: 3px;
  color: white;
  margin-top: 20px;
}

.email-graph {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin: 20px 30px 30px 10px;
  color: #5b6a83 !important;
  height: 430px;
}

.resent-logs-h2 {
  font-size: 1.25rem;
  margin: 10px 0px 20px 0px;
  font-weight: 500;
  color: #596980;
}

.search-field {
  position: relative;
  margin: -50px 0px 20px auto;
}

.search-email {
  width: 260px;
  margin: 0px 0px 0px auto;
  border-radius: 25px;
  height: 37px;
  padding: 20px;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #596980;
  line-height: 110.4%;
  background-color: #f4f6fb;
  border: none;
  display: block;
  padding: 0 42px 0 17px;
}

.search-email::placeholder {
  font-style: italic;
  font-size: 12px;
}

.search-email:focus {
  background-color: #f4f6fb;
  box-shadow: none;
  outline: none;
  border-color: #f4f6fb !important;
}

.search-email-icon {
  background: #f4f6fb;
  padding-right: 15px;
  border-radius: 100px;
  padding-left: 1px;
  margin: 0px;
}

.close-email-icon {
  background: #f4f6fb;
  border-radius: 100px;
  margin: 0px 0px 0px auto;
  width: 37px;
  height: 37px;
  padding-right: 17px;
  padding-left: 7px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer !important;
}

.resent-logs-box {
  position: relative;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin: 20px 30px 30px 10px;
  height: fit-content;
}

.email-thead {
  color: #596980;
  background: #f4f6fb;
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  font-size: 14px !important;
}

#status {
  padding: 12px 0px 12px 12px;
  width: 145px;
  font-weight: normal;
}

#sender {
  width: 200px;
  padding: 12px 0px 12px 0px;
  font-weight: normal;
}

#organization,
#email {
  padding: 12px 0px 12px 0px;
  font-weight: normal;
  width: 145px;
}

#subject {
  width: 180px;
  font-weight: normal;
  padding: 12px 0px 12px 12px;
}

#opens,
#clicks {
  width: 50px;
  font-weight: normal;
  padding: 12px 0px 12px 12px;
}

#resend {
  width: 90px;
  font-weight: normal;
  padding: 12px 0px 12px 20px;
}

.send-view {
  word-spacing: 6px;
}

.email-td {
  font-size: 14px !important;
  color: #596980;
  border-bottom: 1px solid #f4f6fb !important;
  cursor: pointer;
}

.opens,
.clicks {
  text-align: center;
}

.email-img-btn {
  border: none;
  background: none;
}

.email-img {
  margin: 0 0.5rem 0.2rem 0;
}

.email-span {
  color: #28a745;
  font-size: 14px;
}

.email-p {
  color: #6c757d;
  font-size: 14px;
}

.sent-date {
  color: #596980 !important;
  font-size: 14px !important;
}

.expanded-row {
  color: #6c757d !important;
  margin-top: 31.5px !important;
}

.expanded-row-certificate {
  color: #6c757d !important;
  margin-top: -5px !important;
}

.pagination-box {
  text-align: center;
  padding: 2rem 0 0 0;
}

.email-header .react-select-custom {
  margin-top: 1px;
}

.email-header .react-select-custom div {
  font-size: 12px;
}

.email-header .react-select-custom .css-13cymwt-control {
  height: 41px;
}