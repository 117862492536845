.menu-text {
    background-color: #3E85F9;
    padding: 5px 8px 5px 10px !important;
    border-radius: 3px;
    color: white;
    transition: none;
}

.menu-icon {
    filter: brightness(0) invert(1);
    padding: 0px 6px;
    color: white;
}

.demo-access-menu {
    cursor: pointer;
    padding-right: 25px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.menu-list-access-codes {
    list-style-type: none;
    float: right;
    padding-right: 20px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: end;
    border-radius: 5px;
    color: #596980;
    cursor: pointer;
}

.menu-list-access-codes li:hover {
    color: #3E85F9;
}

.dropdown-access-li {
    font-size: 14px;
}

.dropdown-access-li:hover {
    color: #3E85F9;
}

.lower-header-access-codes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu-dropdown-show {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.dropdown-menu-access {
    list-style: none;
    position: absolute;
    margin-top: 25px;
    border: none;
    border-radius: 6px;
    background-color: #fff;
    padding: 5px 5px 5px 5px;
    transform: translate(0px, 10px);
    box-shadow: 0px 14px 20px rgba(50, 50, 71, 0.08),
        0px 0px 32px rgba(50, 50, 71, 0.08);
    cursor: pointer;
}

.dropdown-item-access {
    color: #596980;
    cursor: pointer;
    padding: 8px 10px 10px 10px;
}

.dropdown-item-access:hover {
    background-color: #C5C7CC;
}


/* From vista-portal */
.demo-aggregation-container {
    flex: 5;
    background: #f4f6fb;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: SF Pro Display;
    overflow: auto;
}

.demo-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 20px !important;
}

.left-boxes {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 600px;
}

.generate-box {
    background-color: white;
    height: 210px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.generate-header {
    padding: 20px 20px 0px 20px;
    font-size: 20px;
    line-height: 1.3;
}

.demo-link {
    padding-left: 20px;
    font-size: 15px;
}

.demo-icon {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
}

.input-demo-box {
    margin: 10px 20px 20px 20px;
    width: 100%;
    height: 100px;
}

.demo-input {
    width: 60%;
    margin: 20px 18px 0px 0px;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    color: #596980;
    border-width: 1px;
    padding-left: 5px;
}

.generate-button {
    background-color: #3E85F9;
    color: white;
    height: 37px;
    border-color: transparent;
    border-radius: 5px;
    font-size: 13px;
    width: 28%;
    margin-right: 20px;
}

.generate-button:hover {
    color: #3E85F9;
    background-color: white;
    border-color: #3E85F9;
    border-style: solid;
    border-width: 1px;
}

.sendcode-box {
    background-color: white;
    height: 390px;
    border-radius: 10px;
    width: 100%;
}

.sendcode-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #596980;
    padding: 20px 20px 0px 20px;
}

.sendcode-form {
    width: 92%;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding-left: 8px;
    margin: 0px 0px 0px 20px;
}

.sendcode-form::placeholder,
.sendcode-phone::placeholder {
    color: #C5C7CC !important;
    font-size: 14px;
}

.pre-phone {
    height: 37px;
    width: 19%;
    border-radius: 5px;
    border-color: #E3E7EE;
    background-color: white;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding: 0px 8px 0px 8px;
    margin: 0px 10px 0px 20px;
    color: #596980;
}


.sendcode-phone {
    width: 71%;
    height: 37px;
    border-radius: 5px;
    border-color: #E3E7EE;
    border-style: solid;
    font-size: 14px;
    border-width: 1px;
    padding-left: 8px;
}

.send-button {
    background-color: #3E85F9;
    color: white;
    height: 37px;
    border-color: transparent;
    border-radius: 5px;
    font-size: 13px;
    width: 25%;
    margin: 22px 20px;
}

.accescode-overview {
    background-color: white;
    margin-left: 25px;
    height: 600px;
    width: 56%;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
}

.demo-headers {
    color: #596980;
    background-color: #f4f6fb;
    border-bottom: 2px solid transparent;
    font-size: 14px !important;
    width: 100%;
    top: 0px;
}

.demo-head-accesscode {
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.demo-head-date {
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
}

.code-status {
    height: 50px;
    text-align: center;
}

.demo-info {
    color: #596980;
    border-bottom: 2px solid transparent;
    border-radius: 4px;
    font-size: 14px !important;
    width: 100%;
}

.code-info {
    border-bottom: #f4f6fb 2px solid !important;
    text-align: center;
    width: 20%;
}

.demo-status {
    color: #28a745;
}

.demo-status-red {
    color: #FF647C;
}

.demo-accesscode,
.demo-status,
.demo-user,
.demo-date {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}